import { useEffect } from 'react';
import { navigate } from 'gatsby';
import * as cookies from 'js-cookie';

export default () => {
    useEffect(() => {
        const lang = cookies.get('favorite_language')
        if(lang){
            navigate(`/${lang}`);
        }else{
            navigate('/fr');
        }
    }, []);
    return null;
};